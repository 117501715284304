import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../components/layout';
import { graphql } from 'gatsby';
import SeasonYear from '../../components/SeasonYear';
import TuacahnImage from '../../assets/images/publication-banners/tuacahn.jpg';

export const query = graphql`
  query TuacahnQuery {
    allTuacahnCsv {
      nodes {
        Venue
        CD
        FR
        Quantity
        RUNNING_DATES
        SHOW
        Image
      }
    }
  }
`;

export default function Tuacahn({ data }) {
  const tuacahnNodes = data.allTuacahnCsv.nodes;

  const imageRef = '/season-images/tuacahn/';

  return (
    <Layout>
      <Helmet>
        <title>Tuacahn Amphitheatre - Mills Publishing Inc.</title>
        <meta
          name="description"
          content="Advertising and event information for Tuacahn Amphitheatre’s season playbills. Please contact Mills Publishing for availability and rates."
        />
      </Helmet>

      <div id="main" className="alt">
        <section id="one">
          <div className="inner">
            <header className="major">
              <h1>Tuacahn Amphitheatre</h1>
            </header>
            <span className="image main">
              <img src={TuacahnImage} alt="" />
            </span>
            <p className="box">
              One of Utah’s most popular theatres, Tuacahn returns every year to
              celebrate the best of musical productions. To place an ad in
              Tuacahn programs is to expose your brand to a high-quality family
              market.
            </p>
            <ul className="actions">
              <li>
                <a href="https://www.tuacahn.org/" className="button special">
                  <i className="icon fa-globe"> </i>Tuacahn Amphitheatre Website
                </a>
              </li>
            </ul>
            <h2>
              Tuacahn Amphitheatre <SeasonYear /> Season Information
            </h2>
            <p>
              <em>
                Dates are subject to change. Please contact us if you have any
                questions.
              </em>
            </p>
            <p>
              Tuacahn Center for the Arts is a professional, non-profit
              presenting and producing organization that inspires creativity and
              greatness in the human spirit by providing world-class family
              entertainment and education. Tuacahn has become one of the most
              popular theatrical and entertainment venues in Utah.
            </p>
            <h3>Tuacahn Amphitheatre</h3>

            <section className="box">
              <table>
                <tbody>
                  <tr>
                    <td>Amphitheatre File Ready Deadline: April 4, 2024</td>
                  </tr>
                  <td>Amphitheatre Circulation: 171,000</td>
                  <tr>
                    <td>Total Circulation: 236,000</td>
                  </tr>
                </tbody>
              </table>
            </section>
            <div className="grid-wrapper">
              {/* Calls the season json, accesses the ecclesSeason array, and maps/creates a new array */}
              {tuacahnNodes.map((node) => (
                <div className="col-3 box" key={node.id}>
                  {node.Image && (
                    <img
                      style={{ width: 100 + '%' }}
                      src={imageRef + node.Image}
                    />
                  )}
                  <h3>{node.SHOW}</h3>
                  <strong>{node.RUNNING_DATES}</strong> <br />
                  <p>{node.Venue} </p>
                  {node.Quantity && <p> Circulation: {node.Quantity}</p>}
                  {node.FR && <p> File Ready Date: {node.FR}</p>}
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}
